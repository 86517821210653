var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    {
      ref: "templatesRef",
      staticClass: "templates",
      attrs: { color: "#ffffff" },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" },
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      headers: _vm.filteredHeaders,
                      items: _vm.filteredTemplates,
                      search: _vm.searchText,
                      "item-key": "id",
                      height: _vm.tableHeight,
                      "fixed-header": "",
                      "show-select": _vm.editMode,
                      "selectable-key": "canEdit",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      loading: _vm.localListLoading,
                      "loading-text": _vm.$t("listLoadingMsg"),
                      "mobile-breakpoint": _vm.mobileBreakpoint,
                    },
                    on: { "click:row": _vm.openTemplate },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function ({ items, isSelected, select }) {
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container",
                              },
                              _vm._l(items, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.openTemplate(item)
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.openTemplate(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              },
                                            },
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass,
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px",
                                              },
                                              attrs: {
                                                disabled: !item.canEdit,
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": item.title,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.$currentUser.isTeacher
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headers[0].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "pb-btn",
                                              {
                                                staticClass: "mx-3",
                                                class: _vm.mobileCellClass,
                                                attrs: {
                                                  label:
                                                    item.favorite == 1
                                                      ? _vm.$t(
                                                          "unmarkFavoriteLabel"
                                                        )
                                                      : _vm.$t(
                                                          "markFavoriteLabel"
                                                        ),
                                                  small: "",
                                                  fab: "",
                                                  elevation: "0",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.toggleFavorite(
                                                      item
                                                    )
                                                  },
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.toggleFavorite(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                item.favorite == 1
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-solid fa-star",
                                                          attrs: {
                                                            color: "orange",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-regular fa-star",
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[1].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          item.districtId !== 0
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("districtLabel")
                                                  )
                                                ),
                                              ])
                                            : item.schoolId !== 0
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("schoolLabel"))
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("teacherLabel"))
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText,
                                        },
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.headers[2].text)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.canEdit
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                item.editTitle
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            autofocus: "",
                                                            dense: "",
                                                            "hide-details": "",
                                                            "append-icon":
                                                              "fa fa-check",
                                                            value: item.title,
                                                            disabled:
                                                              _vm.localListLoading,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateTitle(
                                                                item,
                                                                $event
                                                              )
                                                            },
                                                            blur: _vm.disableEditable,
                                                            keyup: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.saveTitle(
                                                                item
                                                              )
                                                            },
                                                            "click:append":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.saveTitle(
                                                                  item
                                                                )
                                                              },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.toggleTitleEditable(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ml-2 hover-edit-icon",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fal fa-pencil"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                      ]
                                    ),
                                    _vm.$currentUser.isTeacher
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headers[3].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _vm._l(
                                                  item.appliedToSubjects.slice(
                                                    0,
                                                    7
                                                  ),
                                                  function (connectedClass, i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "d-inline-block ma-1",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              "open-delay":
                                                                "500",
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  connectedClass.classColor,
                                                                              },
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "fa-solid fa-circle-" +
                                                                              _vm._s(
                                                                                connectedClass.className
                                                                                  .substring(
                                                                                    0,
                                                                                    1
                                                                                  )
                                                                                  .toLowerCase()
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  connectedClass.className
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                item.appliedToSubjects.length >
                                                7
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "14px",
                                                                            },
                                                                        },
                                                                        "span",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "..."
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      _vm._l(
                                                        item.appliedToSubjects,
                                                        function (
                                                          connectedClass,
                                                          i
                                                        ) {
                                                          return _c(
                                                            "v-icon",
                                                            {
                                                              key: i,
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  connectedClass.classColor,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-solid fa-circle-" +
                                                                  _vm._s(
                                                                    connectedClass.className
                                                                      .substring(
                                                                        0,
                                                                        1
                                                                      )
                                                                      .toLowerCase()
                                                                  )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.$currentUser.isAdmin
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headers[4].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.teacherConsumeCount
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.$currentUser.isAdmin
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headers[5].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.appliedToSubjects
                                                      .length
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[6].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.stringifyDate(
                                                item.lastUpdate,
                                                true
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass,
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          item.canEdit
                                            ? _c(
                                                "div",
                                                [
                                                  _c("span", {
                                                    staticClass: "mr-16 ml-3",
                                                  }),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-3 hover-button-primary",
                                                      attrs: {
                                                        small: "",
                                                        text: "",
                                                        color:
                                                          _vm.contentBreakpoint ===
                                                          "xs"
                                                            ? "primary"
                                                            : "transparent",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editTemplate(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("editLabel")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-3 hover-button-error",
                                                      attrs: {
                                                        text: "",
                                                        small: "",
                                                        color:
                                                          _vm.contentBreakpoint ===
                                                          "xs"
                                                            ? "error"
                                                            : "transparent",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteSinglePopup(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "deleteLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-3 hover-button-primary",
                                                      attrs: {
                                                        small: "",
                                                        text: "",
                                                        color:
                                                          _vm.contentBreakpoint ===
                                                          "xs"
                                                            ? "primary"
                                                            : "transparent",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.applyTemplate(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$currentUser
                                                              .isAdmin
                                                              ? _vm.$t(
                                                                  "viewLabel"
                                                                )
                                                              : _vm.$t(
                                                                  "applyLabel"
                                                                )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function ($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 600, expandable: false, withActions: false },
          on: { apply: _vm.applyFilters },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("filterLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.filterDialog.isActive,
            callback: function ($$v) {
              _vm.$set(_vm.filterDialog, "isActive", $$v)
            },
            expression: "filterDialog.isActive",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "mt-1",
                        attrs: {
                          label: _vm.$t("classLabel"),
                          "width:64px": "",
                          "display:inline-flex": "",
                          "return-object": "",
                          "hide-details": "auto",
                          items: _vm.filterOptions.classList,
                          "item-text": "className",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.filterDialog.selectedClass,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterDialog, "selectedClass", $$v)
                          },
                          expression: "filterDialog.selectedClass",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("dayLabel"),
                          "width:64px": "",
                          "display:inline-flex": "",
                          "return-object": "",
                          "hide-details": "auto",
                          items: _vm.filterOptions.dayList,
                          "item-text": "title",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.filterDialog.selectedDay,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterDialog, "selectedDay", $$v)
                          },
                          expression: "filterDialog.selectedDay",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("sectionLabel"),
                          "width:64px": "",
                          "display:inline-flex": "",
                          "return-object": "",
                          "hide-details": "auto",
                          items: _vm.filterOptions.sectionList,
                          "item-text": "title",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.filterDialog.selectedSection,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterDialog, "selectedSection", $$v)
                          },
                          expression: "filterDialog.selectedSection",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("span", { staticClass: "title-on-border" }, [
                                _vm._v(_vm._s(_vm.$t("templatesFilterLabel"))),
                              ]),
                              _c(
                                "v-chip-group",
                                {
                                  attrs: {
                                    "active-class":
                                      "accent3--text font-weight-bold",
                                    column: "",
                                    multiple: "",
                                  },
                                },
                                [
                                  _c(
                                    "pb-chip",
                                    {
                                      staticStyle: { display: "inline" },
                                      attrs: { filter: "", outlined: "" },
                                      model: {
                                        value: _vm.filterDialog.showOld,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterDialog,
                                            "showOld",
                                            $$v
                                          )
                                        },
                                        expression: "filterDialog.showOld",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("oldTemplateLabel"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "pb-chip",
                                    {
                                      staticStyle: { display: "inline" },
                                      attrs: { filter: "", outlined: "" },
                                      model: {
                                        value: _vm.filterDialog.showHidden,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterDialog,
                                            "showHidden",
                                            $$v
                                          )
                                        },
                                        expression: "filterDialog.showHidden",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("hiddenTemplateLabel"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "pb-chip",
                                    {
                                      staticStyle: { display: "inline" },
                                      attrs: { filter: "", outlined: "" },
                                      model: {
                                        value: _vm.filterDialog.hideInactive,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterDialog,
                                            "hideInactive",
                                            $$v
                                          )
                                        },
                                        expression: "filterDialog.hideInactive",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("hideInactiveTemplatesLabel")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.filterDialog.showOld
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("schoolYearLabel"),
                              "width:64px": "",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.filterOptions.yearList,
                              "item-text": "text",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.filterDialog.selectedYear,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterDialog, "selectedYear", $$v)
                              },
                              expression: "filterDialog.selectedYear",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }