
import { Component, Vue, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';
import { UserSession } from '@/common/user-session';
import Confirm from '@/components/core/Confirm.vue';
import ld from 'lodash';
import { FormError } from '@/errors';
import TemplatesMixin from '@/mixins/templates-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import DateTimeUtils from '@/utils/date-time-utils';
import { tableWidths } from '@/constants/index';

const template = namespace('templates');
const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  mixins: [PageLifeCycleMixin, TemplatesMixin, TableResizeMixin]
})
export default class Templates extends Vue {
  $refs!: {
    confirm: Confirm
  }

  headers = [
    { text: this.$t('favoriteLabel'), value: 'favorite', width: '120px', mode: ['T'] },
    { text: this.$t('typeLabel'), value: 'type', width: this.tableWidths.shortText, mode: ['T', 'A'] },
    { text: this.$t('titleLabel'), value: 'title', mode: ['T', 'A'], class: 'header-longText' },
    { text: this.$t('classesLabel'), value: 'appliedToSubjects', width: this.tableWidths.mediumText, mode: ['T'] },
    { text: this.$t('teacherLabel'), value: 'teacherCount', width: this.tableWidths.shortText, mode: ['A'] },
    { text: this.$t('classesLabel'), value: 'classCount', width: this.tableWidths.shortText, mode: ['A'] },
    { text: this.$t('lastUpdateLabel'), value: 'lastUpdate', width: this.tableWidths.fullDate, mode: ['T', 'A'], sort: DateTimeUtils.compareDates },
    { width: this.tableWidths.action3, sortable: false, value: 'action', mode: ['T', 'A'] }
  ];

  allTemplates: Array<any> = [];
  filteredTemplates: Array<any> = [];
  selectedRows!: Array<any>;
  searchText!: string;
  editMode!: boolean;
  isFiltered!: boolean;
  userInfo: any;
  tableHeight!: any;
  templatesFilterChips: Array<any> = [];

  filterDialog = {
    isActive: false,
    selectedClass: { className: this.$t('allClassesLabel'), classID: 0 },
    selectedDay: { title: this.$t('allDaysLabel'), key: 'A' },
    selectedSection: { title: this.$t('allSectionsLabel'), key: 'All' },
    showOld: false,
    selectedYear: { text: this.$t('allSchoolYearLabel'), value: 0 },
    showHidden: false,
    hideInactive: false,
    templatesFilterChips: this.templatesFilterChips,
    filterCount: 0
  }

  filterOptions = {
    classList: [{ className: this.$t('allClassesLabel'), classID: 0 }],
    dayList: [
      { title: this.$t('allDaysLabel'), key: 'A' },
      { title: this.$t('everydayLabel'), key: 'E' }
    ],
    sectionList: [
      { title: this.$t('allSectionsLabel'), key: 'All' },
      { title: this.$t('lessonLabel'), key: 'L' },
      { title: this.$t('homeworkLabel'), key: 'H' },
      { title: this.$t('notesLabel'), key: 'N' },
      { title: this.$t('standardsLabel'), key: 'S' },
      { title: this.$t('attachmentsLabel'), key: 'A' }
    ],
    yearList: [{ text: this.$t('allSchoolYearLabel'), value: 0 }]
  }

  get tableWidths() {
    return tableWidths;
  }

  @template.Action
  loadTemplates!: (param?: any) => Promise<any>;

  @template.Action
  toggleFavoriteStatus!: (param?: any) => Promise<any>;

  @template.Action
  deleteTemplate!: (param?: any) => Promise<any>;

  @template.Action
  deleteTemplates!: (param?: any) => Promise<any>;

  @template.Action
  saveTemplate!: (param: any) => Promise<any>;

  @template.Getter
  getAllTemplates!: any;

  @template.Getter
  getListLoading!: any;

  @classes.Getter
  getClassItems!: Array<any>;

  @classes.Getter
  cycleDays!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter
  getLessonSections!: any;

  @settings.Getter
  getSchoolYearItems!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @Watch('localListLoading')
  disableEditable() {
    if (!this.localListLoading) {
      const templates = ld.cloneDeep(this.filteredTemplates);
      templates.forEach(t => {
        t.editTitle = false;
      });
      this.filteredTemplates = templates;
    }
  }

  @Watch('editMode')
  updateSelectedRows(v: boolean) {
    if (!v) {
      this.selectedRows = [];
    }
  }

  get localListLoading() {
    return this.getListLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('templates/setListLoading', value);
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  determinePermissions() {
    for (const i in this.allTemplates) {
      this.allTemplates[i].canEdit = (!this.allTemplates[i].isAdminTemplate || (this.$currentUser.isAdmin && this.allTemplates[i].districtId === 0) || this.$currentUser.isDistrictAdmin)
    }
  }

  get filteredHeaders() {
    return this.headers.filter((a) => a.mode.includes(this.userMode));
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  // ---- ATTACHMENT FUNCTIONS -----
  get storeAttachments(): Array<any> {
    return this.$store.state.templates.attachments || [];
  }

  set storeAttachments(val: Array<any>) {
    this.$store.commit('templates/setAttachments', val);
  }

  addTemplate() {
    if (this.$currentUser.isAdmin && CommonUtils.isEmpty(this.getLessonSections.lessonLayouts)) {
      this.$refs.confirm.confirm({
        title: this.$t('templateLabel'),
        text: this.$t('layoutRequired'),
        option1ButtonAlternativeText: this.$t('gotoLayout')
      }).then((result) => {
        if (result === 1) {
          this.$router.push({ name: 'Lesson Layouts' });
        }
      });
    } else {
      this.$eventBus.$emit('openSubPage', {
        type: 'template',
        width: 600,
        modal: this.$currentUser.defaultEditorMode === 'modal',
        input: {
          action: 'A'
        }
      });
    }
  }

  openTemplate(param: any) {
    if (param.canEdit) {
      this.editTemplate(param);
    } else {
      this.applyTemplate(param);
    }
  }

  editTemplate(param: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'template',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'E',
        template: param
      }
    });
  }

  applyTemplate(param: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'template',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'V',
        template: param
      }
    });
  }

  deleteSinglePopup(param: any) {
    const that = this;
    this.$refs.confirm.confirm({
      title: this.$t('templateLabel'),
      text: this.$t('confirmDeleteGeneral', { type: this.$t('templateLabel') }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localListLoading = true;
        that.deleteTemplate(param).then(() => {
          this.init(false);
          this.$eventBus.$emit('deleteTemplate', [param.id]);
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg22') as string);
          }
          return Promise.resolve();
        }).catch(() => {
          this.localListLoading = false;
        })
      }
    });
  }

  deleteMultiplePopup() {
    const that = this;
    this.$refs.confirm.confirm({
      title: this.$t('templateLabel'),
      text: this.$t('confirmDeleteMultipleGeneral', { type: this.$t('templateLabel') }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        that.deleteMultiple();
      }
    });
  }

  private deleteMultiple() {
    this.localListLoading = true;
    const templateIds = this.selectedRows.map((t: any) => t.id);
    const areAdmin = this.selectedRows.map((t: any) => t.isAdminTemplate);
    this.deleteTemplates({ templateId: templateIds, isAdminTemplate: areAdmin }).then(() => {
      this.$eventBus.$emit('deleteTemplate', templateIds);
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg22') as string);
      }
      this.selectedRows = [];
      this.init(false);
      return Promise.resolve();
    })
  }

  applyFilters() {
    this.filterDialog.isActive = false;
    this.filteredTemplates = this.allTemplates;
    this.isFiltered = false;
    this.filterDialog.showOld = false;
    this.filterDialog.filterCount = 0;

    this.filteredTemplates = this.filteredTemplates.filter((t) => (t.title !== 'BLANKTEMPLATE') && (t.schoolId !== 0 || t.districtId !== 0 || !this.$currentUser.isAdmin));

    // search
    if (CommonUtils.hasText(this.searchText)) {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
    }
    // selectedClass
    if (this.filterDialog.selectedClass.classID !== 0) {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
      this.filteredTemplates = this.filteredTemplates.filter((t) => {
        for (const c in t.appliedToSubjects) {
          if (t.appliedToSubjects[c].classId === this.filterDialog.selectedClass.classID) {
            return true
          }
        }
        return false;
      })
    }
    // selectedDay
    if (this.filterDialog.selectedDay.key !== 'A') {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
      this.filteredTemplates = this.filteredTemplates.filter((t) => {
        for (const c in t.appliedToSubjects) {
          if (t.appliedToSubjects[c].when.includes(this.filterDialog.selectedDay.key)) {
            return true
          }
        }
        return false;
      })
    }
    // selectedSection
    if (this.filterDialog.selectedSection.key !== 'All') {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
      this.filteredTemplates = this.filteredTemplates.filter((t) => {
        if (this.filterDialog.selectedSection.key === 'S') {
          if (CommonUtils.isNotEmpty(t.standards)) {
            return true;
          }
        } else if (this.filterDialog.selectedSection.key === 'A') {
          if (CommonUtils.isNotEmpty(t.attachments)) {
            return true;
          }
        } else {
          for (const c in t.content) {
            if (t.content[c].section === this.filterDialog.selectedSection.key) {
              return true
            }
          }
        }
        return false;
      })
    }
    // show hidden
    if (this.filterDialog.templatesFilterChips.includes(1)) {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
    } else {
      this.filteredTemplates = this.filteredTemplates.filter((t) => {
        return (t.hide === 0)
      })
    }
    // old templates / school year
    if (this.filterDialog.templatesFilterChips.includes(0)) {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
      this.filterDialog.showOld = true;
      if (this.filterDialog.selectedYear.value !== 0) {
        this.filteredTemplates = this.filteredTemplates.filter((t) => {
          return (t.yearId === this.filterDialog.selectedYear.value);
        })
      }
    }
    // hide inactive
    if (this.filterDialog.templatesFilterChips.includes(2)) {
      this.isFiltered = true;
      this.filterDialog.filterCount += 1;
      this.filteredTemplates = this.filteredTemplates.filter((t) => t.appliedToSubjects.length > 0);
    }
  }

  showFilters() {
    this.filterDialog.isActive = true;
  }

  clearFilters() {
    this.filterDialog.selectedClass = { className: this.$t('allClassesLabel'), classID: 0 };
    this.filterDialog.selectedDay = { title: this.$t('allDaysLabel'), key: 'A' };
    this.filterDialog.selectedSection = this.filterOptions.sectionList[0];
    this.filterDialog.showOld = false;
    this.filterDialog.selectedYear = { text: this.$t('allSchoolYearLabel'), value: 0 };
    this.filterDialog.showHidden = false;
    this.filterDialog.showOld = false;
    this.filterDialog.hideInactive = false;
    this.filterDialog.templatesFilterChips = [];
    this.filteredTemplates = this.allTemplates;
    this.searchText = '';
    this.isFiltered = false;
  }

  toggleFavorite(param: any) {
    this.localListLoading = true;
    this.toggleFavoriteStatus(param).then(() => {
      param.favorite = (param.favorite === 1) ? 0 : 1;
      this.localListLoading = false;
    }).catch((e) => {
      console.log('error', e);
      this.localListLoading = false;
    })
  }

  init(initialLoad:boolean) {
    initialLoad ? CommonUtils.showLoading() : this.localListLoading = true;
    this.$store.commit('templates/setHaveTemplates', false);
    this.loadTemplates({
      teacherId: this.userInfo?.teacherId,
      userType: this.userMode
    }).then(() => {
      this.getAllTemplates.forEach((t: any, n: number) => {
        if (t.legacy) {
          var className = (t.appliedToSubjects[0]) ? t.appliedToSubjects[0].className : '';
          var day = (t.appliedToSubjects[0] && t.appliedToSubjects[0].when) ? t.appliedToSubjects[0].when[0] : '';
          var type = (t.types) ? t.types[0] : '';
          var schoolYearName = '';
          switch (day) {
            case 'E': day = this.$t('everydayLabel').toString(); break;
            case 'M': day = this.$t('mondayLabel').toString(); break;
            case 'T': day = this.$t('tuesdayLabel').toString(); break;
            case 'W': day = this.$t('wednesdayLabel').toString(); break;
            case 'R': day = this.$t('thursdayLabel').toString(); break;
            case 'F': day = this.$t('fridayLabel').toString(); break;
            case 'S': day = this.$t('saturdayLabel').toString(); break;
            case 'U': day = this.$t('sundayLabel').toString(); break;
            default: break;
          }
          switch (type) {
            case 'L': type = this.getLessonSections.lesson.lessonLabel; break;
            case 'H': type = this.getLessonSections.tab2.tab2Label; break;
            case 'N': type = this.getLessonSections.tab3.tab3Label; break;
            case '4': type = this.getLessonSections.tab4.tab4Label; break;
            case '5': type = this.getLessonSections.tab5.tab5Label; break;
            case '6': type = this.getLessonSections.tab6.tab6Label; break;
            case 'S': type = this.getLessonSections.sharedStandards.sharedStandardsLabel; break;
            case 'SS': type = this.getLessonSections.schoolStandards.schoolStandardsLabel; break;
            case 'MS': type = this.getLessonSections.myStandards.myStandardsLabel; break;
            default: break;
          }
          for (var e = 0; e < this.getSchoolYearItems.length; e++) {
            if (t.yearId === this.getSchoolYearItems[e].value) {
              schoolYearName = this.getSchoolYearItems[e].text;
              break;
            }
          }
          this.getAllTemplates[n].title = className + ' | ' + day + ' | ' + type;
          if (CommonUtils.hasText(schoolYearName)) {
            this.getAllTemplates[n].title += ' | ' + schoolYearName
          }
        } else if (t.title.includes('~PB-CONVERTED~')) {
          this.getAllTemplates[n].title = this.$t('untitledTemplateLabel');
        }
      });
      this.allTemplates = ld.cloneDeep(this.getAllTemplates);
      this.determinePermissions();
      if (initialLoad) {
        this.clearFilters();
      }
      this.applyFilters();
      CommonUtils.hideLoading();
      this.localListLoading = false;
    })
  }

  onUserModeChanged() {
    this.init(false);
  }

  created() {
    CommonUtils.showLoading();
    this.userInfo = UserSession.getCurrentUser();
    this.init(true);

    this.filterOptions.yearList = this.filterOptions.yearList.concat(this.getSchoolYearItems);

    for (const i in this.getClassItems) {
      const o = { className: this.getClassItems[i].text, classID: this.getClassItems[i].value };
      this.filterOptions.classList.push(o);
    }

    for (const i in this.cycleDays) {
      const dayKey = (this.cycleDays[i].title === 'Thursday') ? 'R' : this.cycleDays[i].title.substring(0, 1)
      this.filterOptions.dayList.push({ title: this.cycleDays[i].title, key: dayKey })
    }

    this.$nextTick(() => {
      this.$eventBus.$on('refreshTemplates', () => {
        this.init(false);
      });

      this.$eventBus.$on('userModeChanged', this.onUserModeChanged)
      this.$eventBus.$emit('openDefaultSubPage');
    })
  }

  destroyed() {
    this.$eventBus.$off('refreshTemplates');
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
  }

  toggleTitleEditable(item: any) {
    const templates = ld.cloneDeep(this.filteredTemplates);
    templates.forEach(t => {
      t.editTitle = false;
    });
    const template = templates.find(t => t.id === item.id);
    template.editTitle = !template.editTitle;
    this.filteredTemplates = templates;
  }

  updateTitle(item: any, value: string) {
    const templates = ld.cloneDeep(this.filteredTemplates);
    const template = templates.find(t => t.id === item.id);
    template.title = value;
    this.filteredTemplates = templates;
  }

  saveTitle(item: any) {
    const templates = ld.cloneDeep(this.filteredTemplates);
    const template = templates.find(c => c.id === item.id);
    const origTemplate = this.allTemplates.find(c => c.id === item.id);
    this.localListLoading = true;
    if (item.title !== origTemplate.title) {
      this.clearFields(template);
      // text sections
      for (const section in template) {
        switch (template[section].section) {
          case 'L':
            template.lessonContent = template[section].value || ''
            break;
          case 'H':
            template.homeworkContent = template[section].value || ''
            break;
          case 'N':
            template.notesContent = template[section].value || ''
            break;
          case '4':
            template.tab4Content = template[section].value || ''
            break;
          case '5':
            template.tab5Content = template[section].value || ''
            break;
          case '6':
            template.tab6Content = template[section].value || ''
            break;
        }
      }
      // non-text sections
      this.insertMyStandards(template)
      this.insertStandards(template);
      this.insertSchools(template);
      this.insertStrategies(template);
      template.hasAttachments = CommonUtils.isNotEmpty(template.attachments);
      // applied classes
      const appClassList = template.appliedToSubjects;
      template.appliedClasses = []
      for (const i in appClassList) {
        this.addAppliedClass(appClassList[i], template);
      }
      this.saveTemplate(template)
        .then(() => {
          this.localListLoading = false;
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg21') as string);
          }
        })
        .catch((error) => {
          if (!(error instanceof FormError)) {
            return Promise.reject(error);
          }
          this.localListLoading = false;
        });
    } else {
      this.$nextTick(() => {
        this.localListLoading = false;
      })
    }
  }

  insertMyStandards(template: any) {
    template.selectedMyStandards = ld.cloneDeep(template.myStandards);
    if (template.selectedMyStandards) {
      template.selectedMyStandards.map((s: any) => {
        s.dbId = s.standardDBId
        s.id = s.standardId
        s.desc = s.standardDesc
      })
    }
  }

  insertStandards(template: any) {
    template.selectedStandards = ld.cloneDeep(template.standards);
    for (const i in template.selectedStandards) {
      template.selectedStandards[i].value = template.selectedStandards[i].desc || template.selectedStandards[i].standardDesc;
      template.selectedStandards[i].shortValue = template.selectedStandards[i].id || template.selectedStandards[i].standardId;
    }
    template.hasStandards = CommonUtils.isNotEmpty(template.selectedStandards);
  }

  insertSchools(template: any) {
    template.selectedSchools = ld.cloneDeep(template.schoolStandards);
    for (const i in template.selectedSchools) {
      template.selectedSchools[i].dbId = template.selectedSchools[i].dbId || template.selectedSchools[i].standardDBId;
      template.selectedSchools[i].value = template.selectedSchools[i].desc || template.selectedSchools[i].standardDesc;
      template.selectedSchools[i].shortValue = template.selectedSchools[i].id || template.selectedSchools[i].standardId;
    }
    template.hasSchools = CommonUtils.isNotEmpty(template.selectedSchools);
  }

  insertStrategies(template: any) {
    template.strategyItems = ld.cloneDeep(template.strategies);
    for (const i in template.strategyItems) {
      template.strategyItems[i].shortValue = template.strategyItems[i].code;
      template.strategyItems[i].value = template.strategyItems[i].title;
    }
    template.hasStrategies = CommonUtils.isNotEmpty(template.strategyItems);
  }

  addAppliedClass(param:any, template:any) {
    const dayList: Array<any> = [];
    const classList: Array<any> = [];
    // const classMap = this.getClassesMap;
    // load days
    for (const i in this.cycleDays) {
      const dayKey = (this.cycleDays[i].title === 'Thursday') ? 'R' : this.cycleDays[i].title.substring(0, 1)
      dayList.push({ text: this.cycleDays[i].title, value: dayKey })
    }
    // load classes
    for (const i in this.getClassItems) {
      classList.push(this.getClassItems[i]);
    }
    let when = [];
    if (param.when[0] === 'E') {
      when = dayList.map((d: any) => {
        return d.value
      })
    } else {
      when = dayList.filter((a) => param.when.includes(a.value)).map((d: any) => {
        return d.value;
      });
    }
    const savedAppliedClass = {
      id: 'ac' + CommonUtils.generateUUID(),
      class: classList.filter((a) => a.text === param.classId)[0],
      days: when,
      dayList: ld.cloneDeep(dayList),
      startDate: param.start,
      endDate: param.end,
      customDayId: param.customDayId,
      dateError: '',
      dateRules: [() => '']
    }

    template.appliedClasses.push(savedAppliedClass)
  }

  clearFields(template:any) {
    template.lessonContent = '';
    template.homeworkContent = '';
    template.notesContent = '';
    template.tab4Content = '';
    template.tab5Content = '';
    template.tab6Content = '';
    template.hasStandards = false;
    template.hasStrategies = false;
    template.hasSchools = false;
    template.selectedStandards = [];
    template.strategyItems = [];
    template.selectedSchools = [];
    template.appliedClasses = Array<any>();
    template.removeAppliedClass = [];
    this.storeAttachments = template.attachments;
  }
}

